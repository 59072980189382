<template>
    <div class="login">
        <div class="login_content">
            <div class="login_logo">
                <el-image style="width: 200px" :src="logoUrl" fit="cover" @error="onLogoError"></el-image>
            </div>
            <div class="login_title">
                <p>{{ title }}</p>
                <a :href="titleA" target="_blank">{{ titleMsg }}</a>
            </div>
            <div class="login_info">
                <div class="info_input">
                    <div class="input_icon login_account_img"></div>
                    <input type="text" placeholder="请输入账号" v-model.trim="username" :disabled="infoDisabled" />
                </div>
                <div class="info_input">
                    <div class="input_icon login_password_img"></div>
                    <input
                        type="password"
                        placeholder="请输入密码"
                        v-model.trim="password"
                        :disabled="infoDisabled"
                        @keydown.enter="loginClick"
                    />
                </div>
            </div>
            <div class="login_extra">
                <el-checkbox v-model="isChecked">勾选下次无需登录</el-checkbox>
            </div>
            <div class="login_button">
                <el-button @click.prevent="loginClick" :disabled="loginLoad">
                    <i class="el-icon-loading" v-if="loginLoad"></i>
                    登录
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import qs from "qs";
import Cookies from "js-cookie";
export default {
    name: "Login",
    data() {
        return {
            // logo
            logoUrl: require("@/assets/view-imgs/login_logo.png"),

            // title
            title: "威有你想有的",
            titleA: "https://vu818.com",
            titleMsg: "www.vu818.com",

            // 登录
            username: "",
            password: "",
            infoDisabled: false,
            loginLoad: false,

            // 勾选
            isChecked: true,

            remenber: false,
        };
    },
    created() {
        // 记住密码
        let username = localStorage.getItem("username");
        let password = localStorage.getItem("password");
        if (username && password) {
            this.username = localStorage.getItem("username");
            this.password = localStorage.getItem("password");
            this.remenber = true;
        }
    },
    mounted() {},
    computed: {},
    methods: {
        // logo -- 图片未加载
        onLogoError() {
            this.logoUrl = require("@/assets/extra-imgs/图片不存在.png");
        },
        // 登录
        loginClick() {
            this.loginLoad = true;
            this.infoDisabled = true;
            let data = {
                AccountNumber: this.username,
                password: this.password,
            };
            axios.post("/api/logon/login", qs.stringify(data)).then(res => {
                if (res.data.msg) {
                    this.$message.error(res.data.msg);
                    this.loginLoad = false;
                    this.infoDisabled = false;
                    this.password = "";
                } else {
                    // 成功登录
                    this.loginLoad = false;
                    // 7天过期
                    Cookies.set("token", res.data.token, this.isChecked ? { expires: 7 } : undefined);
                    // 用户的id
                    // Vue.prototype.$userId = res.data.id;
                    // 用户的等级
                    Vue.prototype.$userLevel = res.data.level;
                    // 用户可用板块
                    Vue.prototype.$userMenu = res.data.menu;
                    this.$router.push("/home/vyHomePage");
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.login {
    width: 100%;
    height: 100vh;
    background-color: #f2edf3;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .login_content {
        padding: 20px 40px 0;
        box-sizing: border-box;
        width: 410px;
        height: 540px;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .login_title {
            align-self: flex-start;
            p {
                height: 21px;
                line-height: 21px;
                font-size: 18px;
            }

            a {
                color: #b66dff;
            }
        }

        .login_info {
            width: 100%;

            .info_input {
                position: relative;
                margin: 20px 0;

                .input_icon {
                    width: 26px;
                    height: 26px;
                    border-radius: 50%;
                    // background-color: skyblue;
                    position: absolute;
                    top: 18px;
                    left: 10px;
                }

                input {
                    width: 100%;
                    height: 54px;
                    padding-left: 35px;
                    border: 1px solid #ebedf2;
                    box-sizing: border-box;
                    background-color: #e8f0fe;
                    border-radius: 13px;

                    &:focus {
                        outline: 1px solid skyblue;
                    }
                }
            }
        }

        .login_button {
            width: 100%;

            button {
                width: 100%;
                height: 50px;
                border-radius: 13px;
                font-size: 18px;
                color: white;
                display: block;
                border: 0;
                background: linear-gradient(to bottom, #da8cff, #9a55ff);
                // cursor: pointer;
            }
        }

        .login_extra {
            width: 100%;
            margin: 0 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            a {
                font-size: 14px;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.login_account_img {
    width: 16px;
    height: 16px;
    background: url("../assets/view-imgs/login_sprites.png") -46px -10px;
}

.login_password_img {
    width: 16px;
    height: 16px;
    background: url("../assets/view-imgs/login_sprites.png") -10px -10px;
}
</style>
