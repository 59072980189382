<template>
    <div class="vy-icon" :style="{ transform: `scale(${scale})` }">
        <img src="https://iv.vu818.com/img/vuht.png" alt="" :style="style" />
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        scale: {
            type: String,
            default: "1",
        },
    },
    data() {
        return {
            list: [
                //#region
                {
                    name: "添加一级",
                    style: {
                        top: "-42px",
                        left: "1px",
                    },
                },
                {
                    name: "添加二级",
                    style: {
                        top: "-42px",
                        left: "-14px",
                    },
                },
                {
                    name: "添加三级",
                    style: {
                        top: "-42px",
                        left: "-28px",
                    },
                },
                {
                    name: "添加四级",
                    style: {
                        top: "-42px",
                        left: "-43px",
                    },
                },
                {
                    name: "回到顶部",
                    style: {
                        top: "1px",
                        left: "-175px",
                    },
                },
                {
                    name: "arrowTop",
                    style: {
                        top: "0px",
                        left: "0px",
                    },
                },
                {
                    name: "arrowRight",
                    style: {
                        top: "1px",
                        left: "-43px",
                    },
                },
                {
                    name: "arrowBottom",
                    style: {
                        top: "1px",
                        left: "-14px",
                    },
                },
                {
                    name: "arrowLeft",
                    style: {
                        top: "0px",
                        left: "0px",
                    },
                },
                {
                    name: "全屏放大",
                    style: {
                        top: "1px",
                        left: "-205px",
                    },
                },
                {
                    name: "全屏缩小",
                    style: {
                        top: "-14px",
                        left: "-205px",
                    },
                },
                {
                    name: "顾客之声",
                    style: {
                        top: "1px",
                        left: "-73px",
                    },
                },
                {
                    name: "服务器到期",
                    style: {
                        top: "1px",
                        left: "-102px",
                    },
                },
                {
                    name: "退出",
                    style: {
                        top: "1px",
                        left: "-131px",
                    },
                },
                {
                    name: "注册子账号",
                    style: {
                        top: "1px",
                        left: "-87px",
                    },
                },
                {
                    name: "确认",
                    style: {
                        top: "1px",
                        left: "-161px",
                    },
                },
                {
                    name: "取消",
                    style: {
                        top: "-28px",
                        left: "-205px",
                    },
                },
                {
                    name: "重新加载",
                    style: {
                        top: "1px",
                        left: "-190px",
                    },
                },
                //#endregion

                //#region 左侧导航的图标
                {
                    name: "广告图",
                    style: {
                        top: "-13px",
                        left: "1px",
                    },
                },
                {
                    name: "集团",
                    style: {
                        top: "-13px",
                        left: "1px",
                    },
                },
                {
                    name: "关键词",
                    style: {
                        top: "-13px",
                        left: "-14px",
                    },
                },
                {
                    name: "客户案例",
                    style: {
                        top: "-13px",
                        left: "-29px",
                    },
                },
                {
                    name: "威有动静",
                    style: {
                        top: "-13px",
                        left: "-43px",
                    },
                },
                {
                    name: "解决方案",
                    style: {
                        top: "-15px",
                        left: "-65px",
                        width: "240px",
                    },
                },
                {
                    name: "合作共赢",
                    style: {
                        top: "-13px",
                        left: "-73px",
                    },
                },
                {
                    name: "了解威有",
                    style: {
                        top: "-13px",
                        left: "-87px",
                    },
                },
                {
                    name: "视听威有",
                    style: {
                        top: "-42px",
                        left: "-58px",
                    },
                },
                {
                    name: "推荐有奖",
                    style: {
                        display: "none",
                    },
                },
                {
                    name: "专项服务",
                    style: {
                        top: "-13px",
                        left: "-102px",
                    },
                },
                {
                    name: "定价",
                    style: {
                        top: "-14px",
                        left: "-117px",
                    },
                },
                {
                    name: "借贷",
                    style: {
                        top: "-15px",
                        left: "-145px",
                        width: "240px",
                    },
                },
                {
                    name: "用户",
                    style: {
                        top: "-15px",
                        left: "-161px",
                        width: "240px",
                    },
                },
                {
                    name: "服务器",
                    style: {
                        top: "-13px",
                        left: "-160px",
                    },
                },
                {
                    name: "项目进度",
                    style: {
                        top: "-13px",
                        left: "-175px",
                    },
                },
                {
                    name: "威有云市场",
                    style: {
                        top: "-13px",
                        left: "-190px",
                    },
                },
                {
                    name: "短视频",
                    style: {
                        top: "-27px",
                        left: "1px",
                    },
                },
                {
                    name: "底部",
                    style: {
                        top: "-27px",
                        left: "-14px",
                    },
                },
                {
                    name: "简介",
                    style: {
                        top: "-28px",
                        left: "-28px",
                    },
                },
                {
                    name: "广告屏",
                    style: {
                        top: "-30px",
                        left: "-48px",
                        width: "240px",
                    },
                },
                {
                    name: "导航侧边栏",
                    style: {
                        top: "-28px",
                        left: "-58px",
                    },
                },
                {
                    name: "数字化转型",
                    style: {
                        top: "-28px",
                        left: "-73px",
                    },
                },
                {
                    name: "营销推广",
                    style: {
                        top: "-28px",
                        left: "-87px",
                    },
                },
                {
                    name: "问答",
                    style: {
                        top: "-28px",
                        left: "-102px",
                    },
                },
                {
                    name: "网站信息",
                    style: {
                        top: "-28px",
                        left: "-117px",
                    },
                },
                {
                    name: "菜单权限",
                    style: {
                        top: "-28px",
                        left: "-131px",
                    },
                },
                //#endregion
            ],
        };
    },
    computed: {
        style() {
            let obj = this.list.find(e => e.name === this.name);
            return obj ? { ...obj.style } : {};
        },
    },
};
</script>

<style scoped lang="less">
.vy-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    overflow: hidden;
    position: relative;
    & > img {
        position: absolute;
        width: 220px;
        top: 0px;
        left: 0px;
    }
}
</style>
