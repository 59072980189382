// 定义允许上传的格式
export const imgs = [
    ".jpg",
    ".jpeg",
    ".png",
    ".gif",
    ".svg",
    ".psd",
    ".cdr",
    ".ai",
    ".tif",
    ".bmp",
    ".pcx",
    ".tga",
    ".exif",
    ".fpx",
    ".pcd",
    ".dxf",
    ".ufo",
    ".eps",
    ".raw",
    ".WMF",
    ".webp",
    ".avif",
    ".apng",
];
export const videos = [
    ".mp4",
    ".mpeg",
    ".mpg",
    ".dat",
    ".avi",
    ".navi",
    ".rm",
    ".rmvb",
    ".mpeg-2",
    ".asf",
    ".mov",
    ".wmv",
    ".3gp",
    ".flv",
    ".f4v",
];
export default {};
