<template>
    <div>
        <div class="page">
            <div class="page_header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>客户案例</el-breadcrumb-item>
                    <el-breadcrumb-item>列表</el-breadcrumb-item>
                    <el-breadcrumb-item>添加</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="page_content">
                <el-form :model="form" label-width="80px">
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">案例名称</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <input class="form_input" v-model="form.title" type="text" />
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">类型</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <el-select v-model="form.type_of" placeholder="请选择类型">
                                    <el-option v-for="(item, index) in typeOptions" :key="index" :value="item"> </el-option>
                                </el-select>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">案例分类</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <el-cascader
                                    style="width: 100%"
                                    v-model="form.category_id"
                                    :options="categoryList"
                                    @change="slsectFirst"
                                ></el-cascader>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">关键字</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <el-select v-model="form.keyword" clearable placeholder="请选择关键词(可进行搜索查询)" filterable>
                                    <el-option v-for="(item, index) in keywordOptions" :key="index" :value="item"> </el-option>
                                </el-select>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">签约简介</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <input class="form_input" v-model="form.signing" type="text" />
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">签约时间</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <el-date-picker
                                    v-model="form.signing_time"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期"
                                >
                                </el-date-picker>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">浏览链接</div>
                        </template>
                        <template>
                            <div class="form_value from_httpInput">
                                <el-input placeholder="请输入目标链接" v-model.trim="form.project_url" class="input-with-select">
                                    <div slot="append" style="display: flex">
                                        <span>是否生成二维码?&nbsp;&nbsp;</span>
                                        <el-switch
                                            style="width: auto; height: 20px; margin: 0"
                                            v-model="erweima"
                                            active-text="是"
                                            inactive-text="否"
                                            active-color="#7781f1"
                                        >
                                        </el-switch>
                                    </div>
                                </el-input>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item v-show="!erweima">
                        <template slot="label">
                            <div class="form_label_img">
                                <div class="img_size">203 X 388</div>
                                <div>二维码</div>
                            </div>
                        </template>
                        <template>
                            <div class="form_value">
                                <UploadImg
                                    :src="form.qrcode"
                                    @removeSrc="
                                        () => {
                                            form.qrcode = '';
                                            is_artificial = 1;
                                        }
                                    "
                                    ref="qrcode"
                                ></UploadImg>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label_img">
                                <div class="img_size">横:254 X 139</div>
                                <div class="img_size">横:525 X 295</div>
                                <div class="img_size">横:525 X 440</div>
                                <div class="img_size">竖:254 X 460</div>
                                <div>主图</div>
                            </div>
                        </template>
                        <template>
                            <div class="form_value">
                                <UploadImg
                                    :src="form.little_image"
                                    @removeSrc="form.little_image = ''"
                                    ref="little_image"
                                ></UploadImg>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label_img">
                                <div class="img_size">120 X 50</div>
                                <div>Logo图</div>
                            </div>
                        </template>
                        <template>
                            <div class="form_value">
                                <UploadImg :src="form.logo" @removeSrc="form.logo = ''" ref="logo"></UploadImg>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label_img">
                                <div class="img_size">1920 X 388</div>
                                <div>广告图</div>
                            </div>
                        </template>
                        <template>
                            <div class="form_value">
                                <UploadImg :src="form.big_image" @removeSrc="form.big_image = ''" ref="big_image"></UploadImg>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label_img">
                                <div class="img_size_guige">手机: 70X45</div>
                                <div class="img_size_guige">自适: 150X70</div>
                                <div class="img_size_guige">电脑: 120X70</div>
                                <div>规格图</div>
                            </div>
                        </template>
                        <template>
                            <div class="form_value">
                                <UploadImg :src="form.size_image" @removeSrc="form.size_image = ''" ref="size_image"></UploadImg>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">显示状态</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <el-switch
                                    style="width: auto"
                                    v-model="form.status"
                                    active-color="#7781f1"
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-text="显示"
                                    inactive-text="隐藏"
                                >
                                </el-switch>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item>
                        <template slot="label">
                            <div class="form_label">是否置顶</div>
                        </template>
                        <template>
                            <div class="form_value">
                                <el-switch
                                    style="width: auto"
                                    v-model="form.is_top"
                                    active-color="#7781f1"
                                    :active-value="1"
                                    :inactive-value="0"
                                    active-text="是"
                                    inactive-text="否"
                                >
                                </el-switch>
                            </div>
                        </template>
                    </el-form-item>
                </el-form>
                <el-input class="textarea" type="textarea" v-model="form.page" placeholder="请输入页面名"></el-input>

                <editor
                    :txt="form.ueditorData"
                    :getTxt="
                        txt => {
                            form.ueditorData = txt == '<p><br></p>' ? '' : txt;
                        }
                    "
                ></editor>
            </div>

            <!-- 悬浮按钮 -->
            <div class="page_fab">
                <VyIcon name="确认" scale="4" @click.native="updataInfo"></VyIcon>
                <VyIcon name="取消" scale="4" @click.native="onModuleClose"></VyIcon>
            </div>
        </div>
    </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import qs from "qs";
import UploadImg from "@/components/customs/uploadImg.vue";
import editor from "@/components/customs/editor.vue";
import VyIcon from "@/components/customs/VyIcon.vue";

export default {
    components: {
        UploadImg,
        editor,
        VyIcon,
    },
    data() {
        return {
            form: {
                project_url: "",
                qrcode: "",
                logo: "",
                big_image: "",
                little_image: "",
                size_image: "",
                ueditorData: "",
                keyword: "",
                status: 1,
                is_top: 0,
                category_id: [],
                type_of: "共享",
                signing: "",
                signing_time: "",
            },
            // 是否添加水印
            is_artificial: 0,
            // 是否生成二维码
            erweima: false,
            typeOptions: ["共享", "移动端", "电脑端"],
            // 一二级分类列表
            categoryList: [],
        };
    },
    created() {
        this.getKeywordInfo();
        this.getCategory();
    },
    computed: {
        token() {
            return Cookies.get("token");
        },
        keywordOptions() {
            return this.$store.state.keywordModule.totalData;
        },
    },
    watch: {},
    methods: {
        // 获取关键词数据
        getKeywordInfo() {
            this.$store.dispatch("keywordModule/getTotalInfo");
        },
        // 完成修改
        async updataInfo() {
            let form = { ...this.form };
            let { erweima, is_artificial } = this;
            if (erweima && !form.project_url) return this.$message.error("需要自动生成二维码请填写浏览链接");
            form.category_id = form.category_id[1];
            let { qrcode, logo, big_image, little_image, size_image } = this.$refs;
            form.qrcode = qrcode.url;
            form.logo = logo.url;
            form.big_image = big_image.url;
            form.little_image = little_image.url;
            form.size_image = size_image.url;
            // 检查是否勾选生成二维码，是则发送生成二维码请求
            form.project_url = "http://" + form.project_url;
            if (erweima) {
                is_artificial = 0;
                let { data: qrcode } = await axios.post(
                    "/api/project/qrcode",
                    qs.stringify({
                        url: form.project_url,
                    })
                );
                form.qrcode = axios.defaults.baseURL + qrcode;
            }
            form.is_artificial = is_artificial;

            // 最终发送确认请求
            axios
                .post(
                    "/api/Project/list_content",
                    qs.stringify({
                        ...form,
                    })
                )
                .then(res => {
                    if (res.data.code !== 200) return this.$message.error(res.data.msg);
                    this.clearData();
                    this.$message.success(res.data.msg);
                    this.$confirm("已完成添加,是否继续添加?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {})
                        .catch(() => {
                            // this.$store.commit("chooseModule/onChoose", "index");
                            this.$bus.$emit("setBack");
                            this.$router.back();
                        });
                });
        },
        // 清空所有数据
        clearData() {
            this.form = {
                project_url: "",
                qrcode: "",
                logo: "",
                big_image: "",
                little_image: "",
                size_image: "",
                ueditorData: "",
                keyword: "",
                status: 1,
                is_top: 0,
                category_id: [],
                type_of: "共享",
                is_artificial: 0,
                signing_time: "",
                page: "",
            };
            this.erweima = false;
            let { qrcode, logo, big_image, little_image, size_image } = this.$refs;
            qrcode.remove();
            logo.remove();
            big_image.remove();
            little_image.remove();
            size_image.remove();
        },
        // 取消
        onModuleClose() {
            this.clearData();
            // this.$store.commit("chooseModule/onChoose", "index");
            this.$router.back();
        },
        // 获取一二级分类
        async getCategory() {
            let { data } = await axios.get("/api/Project/category");
            data.forEach(e => {
                e.label = e.category_title;
                e.value = e.id;
                e.children.forEach(ele => {
                    ele.label = ele.category_title;
                    ele.value = ele.id;
                });
            });
            this.categoryList = data;
        },
        slsectFirst(arr) {
            let bb = [];
            let i = this.categoryList.findIndex(e => e.id == arr[0]);
            bb = this.categoryList[i].children;
            let index = this.categoryList[i].children.findIndex(e => e.id == arr[1]);
            if (index !== 0) {
                let erarr = bb.splice(index, 1);
                bb = [...erarr, ...bb];
            }
            this.categoryList[i].children = bb;
            let yiarr = this.categoryList.splice(i, 1);
            this.categoryList = [...yiarr, ...this.categoryList];
        },
    },
};
</script>
<style scoped lang="less">
.page {
    .page_content {
        width: 90%;
        padding: 40px;
        .textarea {
            margin-bottom: 22px;
            /deep/ textarea {
                height: 380px;
            }
        }
        .form_label_img {
            width: 80px;
            height: 148px;
            line-height: 148px;
            text-align: center;
            color: white;
            font-size: 16px;
            border-radius: 20px 0 0 20px;
            background: linear-gradient(to right, #da8cff, #9a55ff);
            position: relative;

            .img_size_guige {
                position: absolute;
                font-size: 12px;
                top: -60px;
                left: 10px;

                &:nth-child(2) {
                    margin-top: 15px;
                }

                &:nth-child(3) {
                    margin-top: 30px;
                }
            }

            .img_size {
                position: absolute;
                top: -60px;
                left: 10px;
                font-size: 12px;

                &:nth-child(2) {
                    margin-top: 15px;
                }
                &:nth-child(3) {
                    margin-top: 30px;
                }
                &:nth-child(4) {
                    margin-top: 45px;
                }
            }
        }
        .form_label {
            width: 80px;
            height: 43px;
            line-height: 43px;
            text-align: center;
            color: white;
            font-size: 16px;
            border: 0;
            border-radius: 20px 0 0 20px;
            background: linear-gradient(to right, #da8cff, #9a55ff);
        }

        .form_valBtn {
            display: flex;
            align-items: center;

            img {
                width: 14px;
                height: 14px;
                margin-right: 5px;
            }
        }

        .from_httpInput {
            /deep/ .el-select .el-input {
                width: 90px !important;
            }
            /deep/ .el-input-group__prepend {
                background-color: #fff;
            }

            .httpInput_span {
                cursor: pointer;
            }
        }

        .form_value {
            /deep/ textarea {
                min-height: 43px !important;
            }
            .el-select {
                width: 100% !important;
            }

            .el-switch {
                width: 15%;
                height: 43px;
                line-height: 43px;
                margin-left: 16px;
            }
            /deep/ .el-switch__label {
                height: 43px !important;
            }
            /deep/.el-switch__label.is-active {
                color: #7781f1;
            }
            .el-button {
                height: 43px;
                margin-left: 20px;
                border-radius: 10px;
            }
            /deep/ .el-input__inner {
                height: 43px !important;
            }
            .form_input {
                height: 43px;
                width: 100%;
                padding-left: 15px;
                border: 1px solid #ebedf2;
                box-sizing: border-box;
                background-color: #fff;
                border-radius: 0 13px 13px 0;

                &:focus {
                    outline: 1px solid skyblue;
                }
            }
        }
    }
}
</style>
