<template>
    <!-- 顶部信息栏 -->
    <div class="page_header">
        <div class="page_content">
            <div class="page_left">
                <div class="left_img">
                    <img
                        src="@/assets/view-imgs/login_logo.png"
                        @click="$router.push('/home/vyHomePage')"
                        alt="首页"
                        title="首页"
                    />
                </div>
            </div>
            <div class="page_right">
                <ul>
                    <li>
                        <!-- <el-avatar :size="42" :src="require('@/assets/view-imgs/login_logo.png')"> </el-avatar> -->
                        <img src="@/assets/view-imgs/login_logo.png" alt="" />
                        <el-dropdown trigger="click" @visible-change="val => (rotate = val)">
                            <div class="vy">
                                <span>威有</span>
                                <VyIcon name="arrowBottom" :class="rotate ? 'rotate' : 'vyicon'"></VyIcon>
                            </div>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>
                                    <VyIcon name="注册子账号" scale="1.3"></VyIcon>
                                    <span style="margin-left: 8px" @click="$router.push('/register')">注册子账户</span>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </li>
                    <li>
                        <el-tooltip class="item" effect="dark" content="全屏" placement="bottom">
                            <VyIcon
                                v-if="is_quanping"
                                name="全屏缩小"
                                scale="1.9"
                                @click.native="toggleFullScreen"
                                style="cursor: pointer"
                            ></VyIcon>
                            <VyIcon
                                v-else
                                name="全屏放大"
                                scale="1.9"
                                @click.native="toggleFullScreen"
                                style="cursor: pointer"
                            ></VyIcon>
                        </el-tooltip>
                    </li>
                    <li>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="`顾客之声${unread.mail_UNREAD}条未读`"
                            placement="bottom"
                        >
                            <el-badge :value="unread.mail_UNREAD">
                                <VyIcon
                                    name="顾客之声"
                                    scale="1.9"
                                    @click.native="$router.push('/home/vyMessage_lb')"
                                    style="cursor: pointer"
                                ></VyIcon>
                            </el-badge>
                        </el-tooltip>
                    </li>
                    <li>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="`${unread.server_UNREAD}台服务器将要到期`"
                            placement="bottom"
                        >
                            <el-badge :value="unread.server_UNREAD">
                                <VyIcon
                                    name="服务器到期"
                                    scale="1.9"
                                    @click.native="$router.push('/home/vyServer_lb')"
                                    style="cursor: pointer"
                                ></VyIcon>
                            </el-badge>
                        </el-tooltip>
                    </li>
                    <li>
                        <el-tooltip class="item" effect="dark" content="退出登录" placement="bottom">
                            <VyIcon name="退出" scale="1.9" @click.native="logOut" style="cursor: pointer"></VyIcon>
                        </el-tooltip>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import VyIcon from "@/components/customs/VyIcon.vue";
export default {
    components: { VyIcon },
    data() {
        return {
            searchVal: "",
            is_quanping: false,
            is_click: false,
            isFull: false,
            unread: {
                mail_UNREAD: 0,
                server_UNREAD: 0,
            },
            rotate: false,
        };
    },
    created() {
        this.unread = { ...this.$unread };
        this.$bus.$on("updateUnread", this.updateUnread);
        window.onresize = () => {
            this.$nextTick(() => {
                if (this.is_click) {
                } else {
                    // 没点但是页面大小变化了就是按了esc键
                    this.is_quanping = false;
                }
                this.is_click = false;
            });
        };
    },
    methods: {
        // 全屏
        toggleFullScreen() {
            this.is_click = true;
            if (this.is_quanping) {
                // 退出全屏
                if (document.exitFullscreen) {
                    document.exitFullscreen(); // 标准浏览器
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen(); // Firefox
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen(); // Chrome, Safari and Opera
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen(); // Internet Explorer和Edge
                }
                this.is_quanping = false;
            } else {
                // 开启全屏
                const element = document.documentElement; // 获取整个文档的根元素
                if (element.requestFullscreen) {
                    element.requestFullscreen(); // 标准的全屏API
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen(); // 兼容火狐浏览器
                } else if (element.webkitRequestFullscreen) {
                    element.webkitRequestFullscreen(); // 兼容谷歌浏览器和Safari
                } else if (element.msRequestFullscreen) {
                    element.msRequestFullscreen(); // 兼容IE浏览器
                }
                this.is_quanping = true;
            }
        },
        // 退出登录
        logOut() {
            this.$confirm("是否确认退出登录?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "已完成退出登录",
                    });
                    Cookies.remove("token");
                    Cookies.remove("id");
                    this.$router.push("/login");
                })
                .catch(() => {});
        },
        // 更新顶部的未读标红
        async updateUnread() {
            let { data } = await axios.get("/api/logon/tokengo", { headers: { token: Cookies.get("token") } });
            this.unread = { mail_UNREAD: data.mail_UNREAD, server_UNREAD: data.server_UNREAD };
        },
    },
};
</script>
<style scoped lang="less">
.page_header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 777;
    background-color: #fff;

    .page_content {
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .page_left {
            height: 60px;
            flex: 1;
            display: flex;
            align-items: center;

            .left_img {
                display: flex;
                justify-content: center;
                width: 218px;
                img {
                    width: 90px;
                    margin-top: 25px;
                    cursor: pointer;
                }
            }
            .searchBtn {
                &:hover {
                    border-color: transparent !important;
                }
            }
        }

        .page_right {
            flex: 1;
            // float: right;
            // text-align: right;
            display: flex;
            justify-content: flex-end;
            padding: 0 15px;
            .vy {
                display: flex;
                align-items: center;
                & > span {
                    margin-right: 4px;
                }
                .rotate {
                    transition: 0.4s;
                    transform: rotate(360deg) !important;
                }
                .vyicon {
                    transition: 0.4s;
                    transform: rotate(180deg) !important;
                }
            }
            ul {
                width: 380px;
                display: flex;
                align-items: center;
                justify-content: space-around;

                li {
                    list-style: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    // &:not(:nth-child(1)) {
                    //     transform: scale(0.7);
                    //     cursor: pointer;
                    // }

                    &:nth-child(1) {
                        & > img {
                            height: 50px;
                            width: 50px;
                            // border-radius: 50%;
                            object-fit: contain;
                        }

                        .el-dropdown {
                            margin: 0 10px;
                            font-size: 16px;
                            cursor: pointer;

                            i {
                                color: #a9acf2;
                            }
                        }
                    }
                }
            }
        }
    }
}

.el-dropdown-menu {
    .el-dropdown-menu__item {
        &:hover {
            color: #7781f1;
            background-color: #7781f134;
        }
    }
}
</style>
