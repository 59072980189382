<template>
    <!-- 主体内容 -->
    <!--home页：监听home页参数name修改路由 -->
    <div class="page_main">
        <!-- vyServer_edit,vyUsers,vyWebInfo,vyTalents,vyHomePage -->
        <!-- <keep-alive exclude=""> </keep-alive> -->
        <component :is="currentComponent" :key="currentComponent"></component>
        <div
            @click="backToTop"
            class="backTop"
            :style="show ? 'opacity: 1;visibility: visible;' : 'opacity: 0;visibility: hidden;'"
        >
            <VyIcon name="回到顶部" scale="2.5"></VyIcon>
        </div>
    </div>
</template>

<script>
import VyIcon from "@/components/customs/VyIcon.vue";
export default {
    components: {
        VyIcon,
        // 首页
        vyHomePage: () => import("@/components/content/vyHomePage/index.vue"),

        // 广告图
        vyAdPhoto: () => import("@/components/content/vyAdPhoto/index.vue"),

        // 集团
        vyGroup: () => import("@/components/content/vyGroup/index.vue"),

        // 关键字
        keywordList: () => import("@/components/content/vyKeyword/list/keepAlive.vue"),

        // 简介
        caseIntro: () => import("@/components/content/vyCase/briefIntroduction/index.vue"),

        // 客户案例>列表
        caseList: () => import("@/components/content/vyCase/list/keepAlive.vue"),
        // 客户案例>分类
        caseFl: () => import("@/components/content/vyCase/fl/index.vue"),

        // 定价>规格列表
        fixPriceDetails_gglb: () => import("@/components/content/fixPriceDetails/gglb/index.vue"),
        // 定价>总分类
        fixPriceDetails_fl: () => import("@/components/content/fixPriceDetails/fl/index.vue"),

        // 用户
        vyUsers: () => import("@/components/content/vyUsers/index.vue"),

        // 推荐有奖推荐列表
        vyAward_list: () => import("@/components/content/vyAward/index.vue"),
        // 推荐有奖富文本
        vyAward_editor: () => import("@/components/content/vyAward/fuwenben.vue"),

        // 导航右侧链接
        vyNav_right: () => import("@/components/content/vyNav_right/index.vue"),

        // 分享机遇
        vyFxjy: () => import("@/components/content/vyFxjy_and_vyHzhb/vyFxjy/index.vue"),
        // 合作伙伴
        vyHzhb: () => import("@/components/content/vyFxjy_and_vyHzhb/vyHzhb/index.vue"),

        // 借贷
        vyLoan: () => import("@/components/content/vyLoan"),

        // 威有云市场
        vyVun: () => import("@/components/content/vyVun"),

        // 短视频>爆款品
        vyShortVideo_case: () => import("@/components/content/vyShortVideo/vyShortVideo_case"),
        // 短视频>威有推广
        vyShortVideo_promotion: () => import("@/components/content/vyShortVideo/vyShortVideo_promotion"),
        // 短视频>威有海量媒体资源
        vyShortVideo_media: () => import("@/components/content/vyShortVideo/vyShortVideo_media"),
        // 短视频>推广套餐
        vyShortVideo_package: () => import("@/components/content/vyShortVideo/vyShortVideo_package"),

        // 广告屏
        vyAdvertisingScreen: () => import("@/components/content/vyAdvertisingScreen"),
        // 问答
        vyFAQ: () => import("@/components/content/vyFAQ"),

        // 数字化转型>服务优势
        vyDigitalize_advantage: () => import("@/components/content/vyDigitalize/vyDigitalize_advantage"),
        // 数字化转型>覆盖行业
        vyDigitalize_industry: () => import("@/components/content/vyDigitalize/vyDigitalize_industry"),

        // 菜单权限
        vyMenuLevel: () => import("@/components/content/vyMenuLevel/index.vue"),

        // 顾客之声>列表
        vyMessage_lb: () => import("@/components/content/vyMessage/lb.vue"),
        // 顾客之声>贴心专业诊断
        vyMessage_txzyzd: () => import("@/components/content/vyMessage/txzyzd.vue"),

        // 服务器>列表
        vyServer_lb: () => import("@/components/content/vyServer/index.vue"),
        // 服务器>修改固定数据
        vyServer_edit: () => import("@/components/content/vyServer/edit.vue"),

        // vyKnow: () => import('@/components/content/vyKnow/index.vue'),

        // 了解威有>人才招聘>岗位列表
        vyTalents_lb: () => import("@/components/content/vyTalents/index.vue"),
        // 了解威有>人才招聘>人才战略
        vyTalents_rczl: () => import("@/components/content/vyTalents/rczl.vue"),
        // 了解威有>人才招聘>福利待遇
        vyTalents_fldy: () => import("@/components/content/vyTalents/fldy.vue"),

        // 了解威有>荣誉资质>荣誉分类
        vyHonor_fl: () => import("@/components/content/vyHonor/fl.vue"),
        // 了解威有>荣誉资质>全部荣誉
        vyHonor_all: () => import("@/components/content/vyHonor/all.vue"),

        // 了解威有>发展历程
        vyAdevelop: () => import("@/components/content/vyAdevelop/index.vue"),

        // 了解威有>卓越之星
        vyExcellent: () => import("@/components/content/vyExcellent/index.vue"),

        // 项目进度
        vyProject: () => import("@/components/content/vyProject/index.vue"),

        // 营销推广
        vyMarketing: () => import("@/components/content/vyMarketing/index.vue"),

        //解决方案>全部案例
        vySolve_all: () => import("@/components/content/vySolve/vySolve_all/index.vue"),
        //解决方案>分类
        vySolve_fl: () => import("@/components/content/vySolve/vySolve_fl/index.vue"),
        //解决方案>分类
        vySolve_link: () => import("@/components/content/vySolve/vySolve_link/index.vue"),

        // 威有动静>列表
        vyMovement_lb: () => import("@/components/content/vyMovement/vyMovement_lb/index.vue"),
        // 威有动静>分类
        vyMovement_fl: () => import("@/components/content/vyMovement/vyMovement_fl/index.vue"),

        // 底部链接1
        vyBottomLink_main: () => import("@/components/content/vyBottomLink/index.vue"),
        // 底部链接2
        vyBottomLink_most: () => import("@/components/content/vyBottomLink/most/index.vue"),

        // 网站信息
        vyWebInfo: () => import("@/components/content/vyWebInfo/index.vue"),

        // 视听威有
        llvy: () => import("@/components/content/llvy/index.vue"),

        //专项服务
        vySpecificSupport: () => import("@/components/content/vySpecificSupport/index.vue"),
    },
    data() {
        return {
            show: false,
            timer: null,
        };
    },
    computed: {
        currentComponent() {
            return this.$route.params.name;
        },
    },
    watch: {
        "$route.params.name"(newVal) {},
    },
    mounted() {
        window.addEventListener("scroll", () => {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.show = window.scrollY <= 20 ? false : true;
            }, 100);
        });
    },
    methods: {
        // 回到顶部
        backToTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        },
    },
};
</script>

<style lang="less" scoped>
.backTop {
    width: 40px;
    height: 40px;
    // background-color: red;
    border-radius: 50%;
    position: fixed;
    right: 30px;
    bottom: 180px;
    z-index: 9;
    cursor: pointer;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
</style>
