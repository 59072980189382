var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aside"},[_c('div',{staticClass:"aside_box"},[_c('ul',{staticClass:"aside_ul"},[_vm._l((_vm.asideData),function(item,index){return [(item.isShow)?_c('li',{directives:[{name:"show",rawName:"v-show",value:(!(item.value == 'vyHomePage')),expression:"!(item.value == 'vyHomePage')"}],key:index,ref:"one",refInFor:true,staticClass:"aside_li",class:{ aaa: _vm.a[0] == item.label },on:{"click":function($event){return _vm.gotoHomePage(item.value, item.children)}}},[_c('div',{staticClass:"aside_item",on:{"click":function($event){return _vm.clickAnimation1(item.children, item.value + index)}}},[_c('div',{staticClass:"item_title",staticStyle:{"flex":"1"}},[(item.label == '推荐有奖')?_c('img',{staticClass:"tjyj",attrs:{"src":"https://iv.vu818.com/img/tjyj.png"}}):_c('span',[_vm._v(_vm._s(item.label))])]),_c('VyIcon',{attrs:{"name":item.label,"scale":"1.3"}}),_c('VyIcon',{staticClass:"arrow_right_img",class:item.value + index == _vm.activeIndex1 && item.children ? 'arrow_botton' : '',attrs:{"name":"arrowRight"}})],1),_c('ul',{staticClass:"ul",style:(item.value + index == _vm.activeIndex1
                                ? {
                                      height: `${
                                          (item.children ? item.children.filter(e => e.isShow).length : 0) * 39 + item.h
                                      }px`,
                                  }
                                : '')},[_vm._l((item.children),function(el,ii){return [(el.isShow)?_c('li',{key:ii,ref:"two",refInFor:true,on:{"click":function($event){return _vm.goTo(el.route, el.children)}}},[_c('div',{staticClass:"children_item",class:{ bbb: _vm.a[1] == item.label + el.label && !_vm.a[2] },on:{"click":function($event){return _vm.clickAnimation2(el.children, el.route + ii, item)}}},[_c('VyIcon',{staticClass:"arrow_right_img",class:el.route + ii == _vm.activeIndex2 ? 'arrow_botton' : '',attrs:{"name":"arrowRight"}}),_c('div',{staticClass:"item_title"},[_c('span',[_vm._v(_vm._s(el.label))])])],1),_c('ul',{staticClass:"ul",style:(el.route + ii == _vm.activeIndex2
                                            ? { height: `${(el.children ? el.children.length : 0) * 39}px` }
                                            : '')},_vm._l((el.children),function(e,i){return _c('li',{key:i,ref:"three",refInFor:true,on:{"click":function($event){return _vm.goTo(e.route, e.children)}}},[_c('div',{staticClass:"children_detail_item",class:{ bbb: _vm.a[2] == item.label + el.label + e.label }},[_c('div',{staticClass:"item_title"},[_vm._v(" "+_vm._s(e.label)+" ")])])])}),0)]):_vm._e()]})],2)]):_vm._e()]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }