<template>
    <div
        v-loading="loading"
        element-loading-text="上传中请稍等......"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        class="fu"
    >
        <el-upload
            :headers="{ token: token }"
            :limit="1"
            name="thumbnail"
            :action="imgBaseUrl + '/api/Project/upload'"
            list-type="picture-card"
            :multiple="false"
            :file-list="fileList"
            :on-success="success"
            :on-progress="progress"
            :class="url ? 'sc' : ''"
            class="main"
        >
            <i class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }" class="fileDiv">
                <template v-if="inspect(file.url) == 'img'">
                    <img :src="file.url" />
                </template>
                <template v-else>
                    <video :src="file.url"></video>
                </template>
                <div class="hei">
                    <i class="el-icon-zoom-in" @click="preview(file)"></i>
                    &nbsp; &nbsp;
                    <i class="el-icon-circle-close" @click="remove"></i>
                </div>
            </div>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible" width="80%" append-to-body>
            <div class="preview">
                <template v-if="inspect(dialogUrl) == 'img'">
                    <img width="100%" :src="dialogUrl" />
                </template>
                <template v-else>
                    <video width="100%" controls :src="dialogUrl"></video>
                </template>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Cookies from "js-cookie";
import { imgs, videos } from "./format";
export default {
    props: {
        /**
         * 传入图片路径
         */
        src: String,
        id: Number,
    },
    data() {
        return {
            fileList: [],
            token: "",
            url: "",
            dialogVisible: false,
            dialogUrl: "",
            // 上传时的状态
            loading: false,
        };
    },
    created() {
        this.token = Cookies.get("token");
    },
    methods: {
        // 图片上传成功后
        success(url) {
            this.url = axios.defaults.baseURL + url;
            this.fileList = [{ name: "", url: axios.defaults.baseURL + url }];
            this.loading = false;
        },
        // 移除图片时
        remove() {
            this.url = "";
            this.fileList = [];
            // 移除传入的图片路径
            this.$emit("removeSrc");
        },
        // 预览图片
        preview(file) {
            this.dialogUrl = file.url;
            this.dialogVisible = true;
        },
        // 上传时
        progress() {
            this.loading = true;
        },
        // 检查后缀
        inspect(str = "") {
            str = str.trim().replaceAll(" ", "");
            for (let i = 0; i < imgs.length; i++) {
                if (str.indexOf(imgs[i]) > 0) return "img";
            }
            for (let i = 0; i < videos.length; i++) {
                if (str.indexOf(imgs[i]) > 0) return "video";
            }
            return false;
        },
    },
    watch: {
        src: {
            // 初始执行一次
            immediate: true,
            handler(newVal) {
                this.url = newVal ? newVal : "";
                this.fileList = newVal ? [{ name: "", url: newVal }] : [];
            },
        },
    },
};
</script>

<style lang="less" scoped>
.sc {
    /deep/.el-upload {
        display: none;
    }
}
.main {
    /deep/.el-upload--picture-card {
        &:hover {
            border-color: #7781f1 !important;
        }
    }
    /deep/.el-upload-list__item {
        transition: none !important;
        margin: 0px;
    }
}
.fileDiv {
    width: 100%;
    height: 100%;
    position: relative;
    video,
    img {
        height: 100%;
        width: 100%;
    }
    &:hover {
        .hei {
            z-index: 9;
            opacity: 1;
        }
    }
    .hei {
        z-index: -1;
        position: absolute;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.6);
        top: 0;
        left: 0;
        transition: 0.5s;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        i {
            color: white;
            font-size: 24px;
            cursor: pointer;
            &:hover {
                color: #7781f1;
            }
        }
    }
}
.fu {
    width: 148px;
    height: 148px;
    overflow: hidden;
}
.preview {
    img,
    video {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: calc(70vh - 50px);
        object-fit: contain;
    }
}
</style>
