import Vue from "vue";
import Vuex from "vuex";
import loginModule from "./modules/login";
import modules from "./modules/bus";
import databaseModule from "./modules/database";

const { chooseModule, routeModule } = modules;
const { keywordModule } = databaseModule;

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        loginModule,
        chooseModule,
        routeModule,
        keywordModule,
    },
});
