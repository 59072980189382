import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";
import axios from "axios";
Vue.use(VueRouter);
import home from "../views/Home.vue";
import login from "../views/Login.vue";
import set from "@/components/content/vyCase/list/set.vue";
import add from "@/components/content/vyCase/list/add.vue";
// import asidData from '../components/common/asideModule/asidData';

const routes = [
    {
        path: "/",
        redirect: "/home/vyHomePage",
    },
    {
        path: "/login",
        component: login,
    },
    {
        path: "/register",
        component: () => import("@/views/register"),
    },
    {
        path: "/home/:name",
        name: "home",
        component: home,
        children: [
            {
                path: "set/:id",
                name: "caseListSet",
                component: set,
            },
            {
                path: "add",
                name: "caseListAdd",
                component: add,
            },
        ],
    },
];

const router = new VueRouter({
    mode: "hash",
    // mode: "history",
    base: process.env.BASE_URL,
    routes,
});
// 每次刷新页面执行一次token验证
let once = false;
Vue.prototype.$cl = cl;
// 发送token验证方法
async function t() {
    if (once) return;
    if (!Cookies.get("token")) return;
    // 初始进入时判断页面token是否过期
    let { data } = await axios.get("/api/logon/tokengo", { headers: { token: Cookies.get("token") } });
    if (data.code !== 200) {
        // 未通过
        Cookies.remove("token");
        Cookies.remove("id");
    } else {
        // 用户的id
        // Vue.prototype.$userId = data.id;
        // 用户的等级
        Vue.prototype.$userLevel = data.level;
        // 用户可用板块
        Vue.prototype.$userMenu = data.menu;
        Vue.prototype.$unread = {
            mail_UNREAD: data.mail_UNREAD,
            server_UNREAD: data.server_UNREAD,
        };
    }
    once = true;
}
function cl(data) {
    // 处理用户的左侧菜单   data:asidData.js数据   $userMenu:请求携带用户所拥有的菜单权限数据
    data = JSON.parse(JSON.stringify(data));
    const level = Vue.prototype.$userLevel;
    Vue.prototype.$userMenu.forEach(e => {
        data.forEach(el => {
            if (e.title == el.label) {
                el.isShow = true;
                if (el.children) {
                    if (level == 1) {
                        // 等级1 全部
                        el.children.forEach(ele => {
                            ele.isShow = true;
                            if (ele.children) {
                                ele.children.forEach(element => (element.isShow = true));
                            }
                        });
                    } else if (level == 2) {
                        // 等级2 部分
                        let i = el.children.findIndex(ele => ele.label == "列表");
                        el.children[i].isShow = true;
                    }
                }
            }
        });
    });
    return data;
}
// 前置守卫
router.beforeEach(async (to, from, next) => {
    await t(); // token验证
    let token = Cookies.get("token");
    if (to.path === "/login") {
        next();
    } else {
        if (!token) {
            // 没有token时
            next("/login");
        } else {
            // 有token时检查用户等级，只有等级为1才能看到所有板块
            if (Vue.prototype.$userLevel == 1) {
                next();
            } else {
                // 禁止普通用户在地址栏访问权限过高的页面
                let asidData = cl((await import("../components/common/asideModule/asidData")).default);
                try {
                    asidData.forEach(e => {
                        if (to.path == "/home/" + e.value && e.isShow) {
                            next();
                            throw "循环终止";
                        }
                        if (e.children)
                            e.children.forEach(el => {
                                if (to.path == "/home/" + el.route && el.isShow) {
                                    next();
                                    throw "循环终止";
                                }
                                if (el.children) {
                                    el.children.forEach(ele => {
                                        if (to.path == "/home/" + ele.route && ele.isShow) {
                                            next();
                                            throw "循环终止";
                                        }
                                    });
                                }
                            });
                    });
                } catch (error) {
                    // return有用的
                    return;
                }
                next("/home/vyHomePage");
            }
        }
    }
});

export default router;
