import axios from "axios";
import qs from "qs";

const keywordModule = {
    namespaced: true,
    state: {
        // 分页数据
        paging: {
            data: [],
            count: null,
        },

        // 全部数据
        totalData: [],
    },
    mutations: {
        pagingDataValue(state, payload) {
            state.paging.data = payload;
        },
        totalDataValue(state, payload) {
            state.totalData = payload;
        },
    },
    actions: {
        // 分页查询
        getPagingInfo({ commit }, payload) {
            axios
                .post(
                    "/api/key_word/lst",
                    qs.stringify({
                        currentPage: payload.currentPage,
                        currentPageTotal: payload.currentPageTotal,
                        search: payload.search,
                    })
                )
                .then(res => {
                    commit("pagingDataValue", res.data);
                });
        },

        // 全部数据
        getTotalInfo({ commit }) {
            axios.post("/api/key_word/getall").then(res => {
                commit("totalDataValue", res.data);
            });
        },
    },
};

export default { keywordModule };
