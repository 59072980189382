<template>
    <div>
        <el-container class="container">
            <el-header>
                <header-module></header-module>
            </el-header>
            <el-container class="container_content">
                <el-aside width="218px">
                    <aside-module></aside-module>
                </el-aside>
                <el-main>
                    <main-module></main-module>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>
<script>
import mainModule from "@/components/common/mainModule/index.vue";
import asideModule from "@/components/common/asideModule/index.vue";
import headerModule from "@/components/common/headerModule/index.vue";
export default {
    components: {
        mainModule,
        asideModule,
        headerModule,
    },
    data() {
        return {};
    },
    mounted() {},
    computed: {},
    methods: {},
};
</script>
<style scoped lang="less">
* {
    padding: 0;
    margin: 0;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.el-header {
    flex-shrink: 0;
}

.container_content {
    flex: 1;

    .el-aside {
        // border-right: 1px solid #e5e5e5;
    }

    .el-main {
        margin: 0 10px;
        border-radius: 20px 20px 0 0;
        background: #f2edf3;
        padding: 20px;
    }
}
</style>
