const data = [
    // 无children则为一级分类，可点击
    // 有children则有二级分类，点击一级伸缩，只有二级可点击
    { label: "首页", value: "vyHomePage", isShow: true },
    { label: "广告图", value: "vyAdPhoto" },
    { label: "集团", value: "vyGroup" },
    {
        label: "关键词",
        value: "vyKeyword",
        children: [
            {
                route: "keywordList",
                label: "列表",
            },
        ],
    },
    {
        label: "客户案例",
        value: "vyCase",
        children: [
            {
                route: "caseList",
                label: "列表",
            },
            {
                route: "caseFl",
                label: "分类",
            },
        ],
    },
    {
        label: "威有动静",
        value: "vyMovement",
        children: [
            {
                label: "列表",
                route: "vyMovement_lb",
            },
            {
                label: "分类",
                route: "vyMovement_fl",
            },
        ],
    },
    {
        label: "解决方案",
        value: "vySolve",
        children: [
            {
                label: "全部方案",
                route: "vySolve_all",
            },
            {
                label: "分类",
                route: "vySolve_fl",
            },
            {
                label: "头部链接",
                route: "vySolve_link",
            },
        ],
    },
    {
        label: "合作共赢",
        value: "vyFxjy_and_vyHzhb",
        children: [
            {
                label: "分享机遇",
                route: "vyFxjy",
            },
            {
                label: "合作伙伴",
                route: "vyHzhb",
            },
        ],
    },
    {
        label: "了解威有",
        value: "vyKnow",
        children: [
            {
                label: "人才招聘",
                route: "vyTalents",
                children: [
                    {
                        label: "岗位列表",
                        route: "vyTalents_lb",
                    },
                    {
                        label: "人才战略",
                        route: "vyTalents_rczl",
                    },
                    {
                        label: "福利待遇",
                        route: "vyTalents_fldy",
                    },
                ],
            },
            {
                label: "荣誉资质",
                route: "vyHonor",
                children: [
                    {
                        label: "全部荣誉",
                        route: "vyHonor_all",
                    },
                    {
                        label: "荣誉分类",
                        route: "vyHonor_fl",
                    },
                ],
            },
            {
                label: "发展历程",
                route: "vyAdevelop",
            },
            {
                label: "卓越之星",
                route: "vyExcellent",
            },
        ],
    },
    { label: "视听威有", value: "llvy" },
    { label: "专项服务", value: "vySpecificSupport" },
    {
        label: "定价",
        value: "fixPriceDetails",
        children: [
            {
                label: "列表",
                route: "fixPriceDetails_gglb",
            },
            {
                label: "分类",
                route: "fixPriceDetails_fl",
            },
        ],
    },
    { label: "借贷", value: "vyLoan" },
    { label: "用户", value: "vyUsers" },
    {
        label: "服务器",
        value: "vyServer",
        children: [
            {
                label: "列表",
                route: "vyServer_lb",
            },
            {
                label: "参数配置",
                route: "vyServer_edit",
            },
        ],
    },
    { label: "项目进度", value: "vyProject" },
    { label: "威有云市场", value: "vyVun" },
    {
        label: "短视频",
        value: "vyShortVideo",
        children: [
            {
                label: "爆款品",
                route: "vyShortVideo_case",
            },
            {
                label: "威有推广",
                route: "vyShortVideo_promotion",
            },
            {
                label: "威有海量媒体资源",
                route: "vyShortVideo_media",
            },
            {
                label: "推广套餐",
                route: "vyShortVideo_package",
            },
        ],
    },
    {
        label: "底部",
        value: "vyBottomLink",
        children: [
            {
                label: "页脚",
                route: "vyBottomLink_main",
            },
            {
                label: "备案",
                route: "vyBottomLink_most",
            },
        ],
    },
    { label: "简介", value: "caseIntro" },
    { label: "广告屏", value: "vyAdvertisingScreen" },
    { label: "导航侧边栏", value: "vyNav_right" },
    {
        label: "数字化转型",
        value: "vyDigitalize",
        children: [
            {
                label: "服务优势",
                route: "vyDigitalize_advantage",
            },
            {
                label: "覆盖行业",
                route: "vyDigitalize_industry",
            },
        ],
    },
    { label: "营销推广", value: "vyMarketing" },
    {
        label: "推荐有奖",
        value: "vyAward",
        children: [
            {
                label: "推荐列表",
                route: "vyAward_list",
            },
            {
                label: "富文本",
                route: "vyAward_editor",
            },
        ],
    },
    {
        label: "顾客之声",
        value: "vyMessage",
        children: [
            {
                label: "列表",
                route: "vyMessage_lb",
            },
            {
                label: "贴心专业诊断",
                route: "vyMessage_txzyzd",
            },
        ],
    },
    { label: "问答", value: "vyFAQ" },
    { label: "网站信息", value: "vyWebInfo" },
    { label: "菜单权限", value: "vyMenuLevel" },

    // { label: "关于我们", value: "vyAboutUs" },
    // { label: "服务保障1", value: "vySafeguard1" },
    // { label: "服务保障2", value: "vySafeguard2" },
    // { label: "业务字典", value: "vyDictionaries" },
];

export default data;
