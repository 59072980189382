const chooseModule = {
    namespaced: true,
    state: {
        value: '',
    },
    mutations: {
        onChoose(state, payload) {
            state.value = payload;
        },
    },
};

const routeModule = {
    namespaced: true,
    state: {
        value: '',
    },
    mutations: {
        onChoose(state, payload) {
            state.value = payload;
        },
    },
};

export default { chooseModule, routeModule };
