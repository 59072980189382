import axios from 'axios';
import qs from 'qs';
import Cookies from 'js-cookie';
import router from '@/router/index'; // 导入 router 实例

const loginModule = {
    namespaced: true,
    state: {
        account: 'admins', // 账号
        password: '123456', // 密码
        isLoggedIn: false, // 登录状态
    },
    mutations: {
        setAccountData(state, data) {
            state.account = data;
        },
        setPasswordData(state, data) {
            state.password = data;
        },
        setLoggedInState(state, data) {
            state.isLoggedIn = data;
        },
    },
    actions: {
        // 发起登录请求
        onLogin({ state, commit }) {
            let data = {
                AccountNumber: state.account,
                password: state.password,
            };
            axios.post('/api/logon/login', qs.stringify(data)).then(res => {
                const token = res.data.token;
                Cookies.set('token', token);
                commit('setLoggedInState', true);
                router.push('/home');
            });
        },
    },
};

export default loginModule;

//   actions: {

//     // fetchBackendData({ commit }) {
//     //   // 发起后端数据请求，获取数据
//     //   // 例如，使用axios库进行Ajax请求
//     //   axios.get('/api/backend-data')
//     //     .then(response => {
//     //       const data = response.data;
//     //       commit('setBackendData', data); // 将数据缓存到store中
//     //     })
//     //     .catch(error => {
//     //       console.error(error);
//     //     });
//     // },
//     // login({ commit }) {
//     //   this.$http.post(
//     //     "/User_Logon/logon",
//     //     qs.stringify({
//     //       AccountNumber: this.loginInfo.username,
//     //       password: this.loginInfo.password,
//     //     })
//     //   ).then();
//     // 模拟登录操作
//     // 例如，使用axios库进行Ajax请求
//     //   axios.post('/api/login')
//     //     .then(() => {
//     //       commit('setLoggedInState', true); // 更新登录状态
//     //     })
//     //     .catch(error => {
//     //       console.error(error);
//     //     });
//   },
//   // logout({ commit }) {
//   // 模拟登出操作
//   // 例如，使用axios库进行Ajax请求
//   //   axios.post('/api/logout')
//   //     .then(() => {
//   //       commit('setBackendData', null); // 清空后端数据缓存
//   //       commit('setLoggedInState', false); // 更新登录状态
//   //     })
//   //     .catch(error => {
//   //       console.error(error);
//   //     });
//   // },
// });
