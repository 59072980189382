<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    components: {},
    async created() {},
    mounted() {},
    methods: {},
};
</script>

<style lang="less">
* {
    padding: 0;
    margin: 0;
    list-style: none;
    input:focus {
        border-color: #7781f1 !important;
    }
    textarea:focus {
        border-color: #7781f1 !important;
    }
    button {
        background-color: transparent;
    }
}
:root {
    --color-primary: #7781f1 !important;
}
.el-dialog {
    border-radius: 20px !important;
}
.el-select-dropdown {
    .el-select-dropdown__item.selected {
        color: #7781f1;
    }
}
// 全局按钮样式
._operate {
    display: inline-block;
    margin: 0 5px 5px 0;
    button {
        width: 90px;
        height: 36px;
        color: white;
        font-size: 14px;
        border: 0;
        border-radius: 20px;
        background: linear-gradient(to right, #da8cff, #9a55ff);
        cursor: pointer;
    }
}
._add {
    display: inline-block;
    margin: 0 5px 5px 0;
    button {
        width: 90px;
        height: 36px;
        color: white;
        font-size: 14px;
        border: 0;
        border-radius: 20px;
        background: linear-gradient(to right, #84d9d2, #07cdae);
        cursor: pointer;
    }
}
._delete {
    display: inline-block;
    margin: 0 5px 5px 0;
    button {
        width: 90px;
        height: 36px;
        color: white;
        font-size: 14px;
        border: 0;
        border-radius: 20px;
        background: linear-gradient(to right, #f38ca8, #fe7096);
        cursor: pointer;
    }
}
._look {
    display: inline-block;
    margin: 0 5px 5px 0;
    button {
        width: 90px;
        height: 36px;
        color: white;
        font-size: 14px;
        border: 0;
        border-radius: 20px;
        background: linear-gradient(to right, #86d9ff, #19b5fe);
        cursor: pointer;
    }
}
.el-button--primary {
    background-color: #7781f1 !important;
    border-color: #7781f1 !important;
}
.el-button--default {
    &:hover {
        color: #7781f1 !important;
        background-color: transparent !important;
        border-color: #7781f1 !important;
    }
}
.el-upload--picture-card {
    &:hover {
        border-color: #7781f1 !important;
    }
}
.el-cascader-menu {
    width: 25vw;
}
.page_fab {
    position: fixed;
    right: 40px;
    bottom: 50px;
    display: flex;
    flex-direction: column;
    & > div {
        // border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        &:nth-of-type(1) {
            margin-bottom: 60px;
        }
    }
}
</style>
